import i18next from 'i18next';

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: 'en' | 'ru',
        // Using simple hardcoded resources for simple example
        resources: {
            en: {
                translation: {
                    Search: { label: 'Search', },
                },
            },
            ru: {
                translation: {
                    Search: { label: 'Искать', },
                },
            },
        },
    })

export default i18next