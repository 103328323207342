import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

import React, { useState } from 'react';

import './App.css';
import WordsList from "../WordsList";
import {i18next} from '../../config';

function App() {
    const [wordMask, setMask] = useState("*****");
    const [usedLetters, setUsedLetters] = useState("");
    const [excludeLetters, setExcludeLetters] = useState("");

    const onFormSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.target);

        setMask(data.get("formMask").toString());
        setUsedLetters(data.get("formUsedLetters").toString());
        setExcludeLetters(data.get("formExcludeLetters").toString());
    };

    return (
        <Container className="p-3">
            <h1 className="header">5 letters helper</h1>

            <Stack gap={3}>
                <Form onSubmit={onFormSubmit}>
                    <Form.Group className="mb-3" controlId="formMask">
                        <Form.Label>Word mask</Form.Label>
                        <Form.Control type="text" name="formMask" maxLength="5" defaultValue="*****"/>
                        <Form.Text className="text-muted">
                            Enter the word mask, * - any letter.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formUsedLetters">
                        <Form.Label>Used letters</Form.Label>
                        <Form.Control type="text" name="formUsedLetters"/>
                        <Form.Text className="text-muted">
                            Type any letters that word contains.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formExcludeLetters">
                        <Form.Label>Excluded letters</Form.Label>
                        <Form.Control type="text" name="formExcludeLetters"/>
                        <Form.Text className="text-muted">
                            Type any letters that word does not contains.
                        </Form.Text>
                    </Form.Group>

                    <Button type="submit" variant="primary">Search</Button>
                </Form>

                <div>
                    <WordsList
                        wordMask={wordMask}
                        usedLetters={usedLetters}
                        excludeLetters={excludeLetters}
                    />
                </div>
            </Stack>
        </Container>
    );
}

export default App;
