import React, {useEffect, useState} from 'react';

import ListGroup from 'react-bootstrap/ListGroup';
import Pagination from 'react-bootstrap/Pagination';
import Stack from "react-bootstrap/Stack";

import './WordsList.css';

import WordsData from "../../data";
import {AppConfig} from "../../config";


function validateMask(word, mask) {
    for (let i = 0; i < word.length; i++) {
        let wordChar = word.charAt(i);
        let maskChar = mask.charAt(i);
        if (maskChar === '*' || wordChar === maskChar) {
            continue
        } else {
            return false;
        }
    }
    return true;
}

function validateUsedLetters(word, usedLetters) {
    for (let i = 0; i < usedLetters.length; i++) {
        if (word.indexOf(usedLetters.charAt(i)) === -1)
            return false;
    }
    return true;
}

function validateExcludeLetters(word, excludeLetters) {
    for (let i = 0; i < excludeLetters.length; i++) {
        if (word.indexOf(excludeLetters.charAt(i)) > -1)
            return false;
    }
    return true;
}

function filterWords(words, wordMask, usedLetters, excludeLetters) {
    let result = [];

    for (let i = 0; i < words.length; i++) {
        let word = words[i];
        if (validateMask(word, wordMask) &&
            validateUsedLetters(word, usedLetters) &&
            validateExcludeLetters(word, excludeLetters)) {
            result.push(word);
        }
    }
    return result;
}

function getPaged(filtered, currentPage) {
    let items = [];
    let startItem = AppConfig.words_per_page * (currentPage - 1);
    let endItem = Math.min(startItem + AppConfig.words_per_page, filtered.length);
    for (let i = startItem; i < endItem; i++) {
        items.push(<ListGroup.Item key={filtered[i]}>{filtered[i]}</ListGroup.Item>);
    }
    return items;
}

function WordsList({wordMask, usedLetters, excludeLetters}) {
    const [wordsData, setWordsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [listItems, setListItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationItems, setPaginationItems] = useState([]);

    useEffect(() => {
        setWordsData(WordsData);
    }, []);

    useEffect(() => {
        const filtered = filterWords(wordsData, wordMask, usedLetters, excludeLetters);
        setFilteredData(filtered);
        setCurrentPage(1);
    }, [wordsData, wordMask, usedLetters, excludeLetters]);

    useEffect(() => {
        const createPaginationItem = (i) => {
            return (
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Pagination.Item>
            );
        };

        let total = filteredData.length;
        let totalPages = Math.max(1, Math.ceil(total / AppConfig.words_per_page));

        const paginationItems = [];
        if (totalPages > 1) {
            let startPage = Math.max(1, Math.min(currentPage, currentPage - 2));
            let endPage = Math.min(currentPage + 2, totalPages);

            if (startPage > 1) {
                paginationItems.push(createPaginationItem(1));
                paginationItems.push(<Pagination.Ellipsis key="ellipse-start"/>);
            }

            for (let i = startPage; i < endPage; i++) {
                paginationItems.push(createPaginationItem(i));
            }

            if (endPage !== totalPages) {
                paginationItems.push(<Pagination.Ellipsis key="ellipse-end"/>);
            }

            paginationItems.push(createPaginationItem(totalPages));
        }

        setPaginationItems(paginationItems);
        setTotalPages(totalPages);
        setListItems(getPaged(filteredData, currentPage))
    }, [filteredData, currentPage]);

    return (
        <>
            <Stack gap={3}>
                <h2 className="header">Total results: {filteredData.length}</h2>

                <ListGroup>
                    {listItems}
                </ListGroup>

                <p className="text-center">Page {currentPage} of {totalPages}</p>
                <Pagination className="justify-content-center">
                    { totalPages > 1 ?
                    <Pagination.Prev
                        key="prev"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    /> : null }

                    {paginationItems}

                    { totalPages > 1 ?
                    <Pagination.Next
                        key="next"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    /> : null }
                </Pagination>
            </Stack>
        </>

    );
}

export default WordsList;